import axios from "axios";
const {
  ADD_PF_CLIENT,
  ADD_PJ_CLIENT,
  UPDATE_PF_CLIENT,
  UPDATE_PJ_CLIENT,
  GET_ALL_CLIENT,
  DESTROY_CLIENT,
  GET_AUTOCOMPLETE_CLIENT,
  GET_FILTER_CLIENT,
  ITEM_TO_UPDATE_CLIENT,
  GET_ALL_CLIENT_TO_SELECT_FIELD,
  GET_ALL_CLIENT_BY_ENTERPRISE,
} = require("./actions/client");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleClient = {
  state: () => ({
    Phisycalclients: Array(),
    LegalClients: Array(),
    legalAutocomplete: Array(),
    physicalAutocomplete: Array(),
    addedClient: Object(),
    itemToUpdateClient: null,
    itemToDelete: Object(),
    selectClient: Array(),
  }),

  mutations: {
    ITEM_TO_UPDATE_CLIENT: function (state, payload) {
      state.itemToUpdateClient = payload
    },
    GET_ALL_CLIENT_TO_SELECT_FIELD: function (state, payload) {
      state.selectClient = payload
    },
    GET_ALL_CLIENT: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;
      if (payload.type === "PhysicalPeople") {
        while (state.Phisycalclients.length) {
          state.Phisycalclients.pop();
        }
      } else {
        while (state.LegalClients.length) {
          state.LegalClients.pop();
        }
      }

       
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });
          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;
          indx = state.Phisycalclients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.Phisycalclients.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalClients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalClients.push(Infos);
          }
        }
      });
    },

    GET_ALL_CLIENT_BY_ENTERPRISE: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;
      if (payload.type === "PhysicalPeople") {
        while (state.Phisycalclients.length) {
          state.Phisycalclients.pop();
        }
      } else {
        while (state.LegalClients.length) {
          state.LegalClients.pop();
        }
      }

       
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });
          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;
          indx = state.Phisycalclients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.Phisycalclients.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalClients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalClients.push(Infos);
          }
        }
      });
    },

    GET_AUTOCOMPLETE_CLIENT: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;
      if (payload.type === "PhysicalPeople") {
        while (state.physicalAutocomplete.length) {
          state.physicalAutocomplete.pop();
        }
      } else {
        while (state.legalAutocomplete.length) {
          state.legalAutocomplete.pop();
        }
      }

       
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });
          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;
          indx = state.physicalAutocomplete.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.physicalAutocomplete.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.legalAutocomplete.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.legalAutocomplete.push(Infos);
          }
        }
      });
  },

    GET_FILTER_CLIENT: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;
      if (payload.type === "PhysicalPeople") {
        while (state.Phisycalclients.length) {
          state.Phisycalclients.pop();
        }
      } else {
        while (state.LegalClients.length) {
          state.LegalClients.pop();
        }
      }

       
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });
          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;
          indx = state.Phisycalclients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.Phisycalclients.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalClients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalClients.push(Infos);
          }
        }
      });
  },

    ADD_PF_CLIENT: (state, payload) => {
      let Infos = {};
      let physicalpeople = {}
      let contacts = {};
      let address = {};
      let banks = {};

      physicalpeople = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { physicalpeople })
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.addedClient = Infos
      state.Phisycalclients.push(Infos);
    },

    ADD_PJ_CLIENT: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let legalpeople = {};

      legalpeople = { ...payload.people.legalpeople };
      address = payload.people.address;
      contacts = payload.people.contact;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { legalpeople });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.addedClient = Infos
      state.LegalClients.push(Infos);
    },

    UPDATE_PF_CLIENT(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      Infos = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      //   
      // let arrayAuxiliar = state.Phisycalclients
      // arrayAuxiliar[state.itemToUpdateClient.index] = {...Infos, physicalpeople:{name:Infos.name, cpf:Infos.cpf,...Infos}}
      // state.Phisycalclients = Array()
      // state.Phisycalclients = [...arrayAuxiliar]
      // state.itemToUpdateClient = null

      //   

      state.Phisycalclients.Update(Infos);
    },
    UPDATE_PJ_CLIENT(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};

      Infos = { ...payload.people.legalpeople };
      address = payload.people.address;
      contacts = payload.people.contact;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      //   
      // let arrayAuxiliar = state.LegalClients
      // arrayAuxiliar[state.itemToUpdateClient.index] = {...Infos,legalpeople:{...Infos,socialName:Infos.socialName, fantasyName:Infos.fantasyName, cnpj: Infos.cnpj}}
      // state.LegalClients = Array()
      // state.LegalClients = [...arrayAuxiliar]
      // state.itemToUpdateClient = null
      state.LegalClients.Update(Infos);
    },
    DESTROY_CLIENT: (state, payload) => {
      state.itemToDelete = payload
      // let index = -1;
      // if (payload.type_people === "PhysicalPeople") {
      //   let statePhisycal = state.Phisycalclients;
      //   index = state.Phisycalclients.findIndex(
      //     (value) => value.id === payload.id
      //   );
      //   if (index >= 0) {
      //     statePhisycal.splice(index, 1);
      //     state.Phisycalclients = statePhisycal;
      //   }
      // } else if (payload.type_people === "LegalPeople") {
      //   let stateLegal = state.LegalClients;
      //   index = state.LegalClients.findIndex(
      //     (value) => value.id === payload.id
      //   );
      //   if (index >= 0) {
      //     stateLegal.splice(index, 1);
      //     state.LegalClients = stateLegal;
      //   }
      // }
    },
  },

  actions: {
    ITEM_TO_UPDATE_CLIENT: function (context, payload) {
      context.commit(ITEM_TO_UPDATE_CLIENT, payload)
    },
    GET_AUTOCOMPLETE_CLIENT({ commit }, payload) {
      axios
        .post("client/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_AUTOCOMPLETE_CLIENT, response.data[0]);
        });
    },
    GET_FILTER_CLIENT({ commit }, payload) {
      axios
        .post("client/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_FILTER_CLIENT, response.data[0]);
        });
    },
    GET_ALL_CLIENT_TO_SELECT_FIELD({ commit }, payload) {
      axios
        .post("client/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_CLIENT_TO_SELECT_FIELD, response.data[0]);
        });
    },
    GET_ALL_CLIENT({ commit }, payload) {
      axios
        .post("client/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_CLIENT, response.data[0]);
        });
    },

    GET_ALL_CLIENT_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("client/getAllByEnterprise", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          enterprise_id: payload.enterprise_id
        })
        .then((response) => {
          response.data.type = payload.type_people;
          commit(GET_ALL_CLIENT_BY_ENTERPRISE, response.data);
        });
    },

    ADD_PF_CLIENT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("client/createphysicalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PF_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    ADD_PJ_CLIENT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("client/createlegalPeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PJ_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    UPDATE_PF_CLIENT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("client/updatephysicalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PF_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_PJ_CLIENT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("client/updatelegalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PJ_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_CLIENT({ commit }, payload) {
      axios
        .post("client/delete", {
          id: payload.id,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          response, commit(DESTROY_CLIENT, payload);
          commit(SPLICE_ERRO, {
            alert: "Cliente deletado com sucesso!",
            type: "S",
          });
        });
    },
  },

  getters: {
    getItemToDelete: (state) => state.itemToDelete,
    getAddedClients: (state) => state.addedClient,
    getPhysicalClients: (state) => state.Phisycalclients,
    getLegalClients: (state) => state.LegalClients,
    getClientPhysicalAuto: (state) => state.physicalAutocomplete,
    getClientLegalAuto: (state) => state.legalAutocomplete,
    getItemToUpdateClient: (state) => state.itemToUpdateClient,
    getSelectClient: (state) => state.selectClient,
  },
};

export default moduleClient;
