<template>
  <v-container>
  <v-divider></v-divider>
  <v-form ref="form" v-model="component.validAddress">
     <v-spacer></v-spacer>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="component.address.cep"
            outlined
            append-icon="mdi-magnify"
            v-mask="'#####-###'"
            :rules="textRules"
            @click:append="
            () => {
              loadAddress()
            }
              "
            label="*CEP"
            :readonly="component.justView"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.address.street"
            :rules="textRules"
            outlined
            label="*Rua"
            :readonly="component.justView"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2"
          ><v-text-field
            v-model="component.address.number"
            :rules="textRules"
            outlined
            label="*Número"
            :readonly="component.justView"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.address.district"
            :rules="textRules"
            outlined
            label="*Bairro"
            :readonly="component.justView"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.address.complement"
            outlined
            label="*Complemento"
            :rules="textRules"
            :readonly="component.justView"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="oldState"
            :items="initials"
            item-text="text"
            return-object
            :rules="textRules"
            outlined
            label="*Estado"
            :readonly="component.justView"
            required
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="component.cityObject"
            :items="cities"
            item-text="nome"
            return-object
            outlined
            label="*Cidade"
            :rules="[() => !!component.cityObject.nome || 'Campo Obrigatório']"
            :readonly="component.justView"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-form>
  </v-container>
</template>


<script>
import {mapGetters } from "vuex"
import axios from "axios";
export default {
  data: () => ({
    valid: Boolean(),
    textRules: [(v) => !!v || "Campo obrigatório!"],
    initials: [
      { text: "Acre", value: "AC" },
      { text: "Alagoas", value: "AL" },
      { text: "Amapá", value: "AP" },
      { text: "Amazonas", value: "AM" },
      { text: "Bahia", value: "BA" },
      { text: "Ceará", value: "CE" },
      { text: "Espírito Santo", value: "ES" },
      { text: "Goiás", value: "GO" },
      { text: "Maranhão", value: "MA" },
      { text: "Mato Grosso", value: "MT" },
      { text: "Mato Grosso do Sul", value: "MS" },
      { text: "Minas Gerais", value: "MG" },
      { text: "Pará", value: "PA" },
      { text: "Paraíba", value: "PB" },
      { text: "Paraná", value: "PR" },
      { text: "Pernambuco", value: "PE" },
      { text: "Piauí", value: "PI" },
      { text: "Rio de Janeiro", value: "RJ" },
      { text: "Rio Grande do Norte", value: "RN" },
      { text: "Rio Grande do Sul", value: "RS" },
      { text: "Rondônia", value: "RO" },
      { text: "Roraima", value: "RR" },
      { text: "Santa Catarina", value: "SC" },
      { text: "São Paulo", value: "SP" },
      { text: "Sergipe", value: "SE" },
      { text: "Tocatins", value: "TO" },
      { text: "Distrito Federal", value: "DF" },
    ],
    cities: Array(),
    oldState: String(),
    auxCity: String(),
  }),
  props: {
    component: Object(),
  },
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
    loadAddress() {
      axios.get(
        `https://viacep.com.br/ws/${this.component.address.cep}/json`
      )
      .then((response) => {
        if(!response.data.erro) {
        this.component.address.street = response.data.logradouro
        this.component.address.district = response.data.bairro
        this.auxCity = response.data.localidade
        this.oldState = {value: response.data.uf}
      } else if(response.data.erro && this.component.id) {
          this.oldState = {value: this.component.address.state}
          this.auxCity = this.component.address.city.name
        }
      });
    },
    loadCity() {
      this.component.cityObject = {}
      if(this.cities.length != 0) {
        for(let i = 0; i <= this.cities.length; i++) {
          if (this.cities[i].nome == this.auxCity) {
            this.component.cityObject = this.cities[i]
          }
        }
      }
    },

   
  },
  computed: {
    ...mapGetters(["getItemToUpdateClient"])
  },
  created(){
    if(this.component.id) {
      this.loadAddress()
    }
    this.getCities({value: this.component.address.state})
    if(this.getItemToUpdateClient){
      this.oldState = {value:this.getItemToUpdateClient.address[0].state, text:this.getItemToUpdateClient.address[0].name}
      this.getItemToUpdateClient.address[0].city.nome = this.getItemToUpdateClient.address[0].city.name
      this.auxCity = this.getItemToUpdateClient.address[0].city.name
      this.component.cityObject = this.getItemToUpdateClient.address[0].city
    }
  },
  watch: {
    getItemToUpdateClient: function (val) {
      this.oldState = { value: val.address[0].state }
      val.address[0].city.nome = val.address[0].city.name
      this.component.cityObject = val.address[0].city
    },
  
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
          this.component.address.state = val;
          this.loadCity()
        });
    },
  },

  beforeDestroy() {
    this.auxCity = ""
    this.oldState = ""
    this.getItemToUpdateClient.address[0].state = ""
    this.getItemToUpdateClient.address[0].name = ""

  }
  
};
</script>
